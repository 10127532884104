<template>
  <RevButton :disabled :loading variant="primary" @click="$emit('click')">
    {{
      withCheckoutContentCTA
        ? i18n(translations.buttonCheckoutABTest)
        : i18n(translations.buttonCheckout)
    }}
  </RevButton>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'

import { useCartStore } from '../../../../stores/cartStore'

import translations from './CheckoutButton.translations'

defineEmits(['click'])

const experiments = useExperiments()
const i18n = useI18n()
const cartStore = useCartStore()

const withCheckoutContentCTA = computed(
  () =>
    experiments['experiment.checkoutContentCTA'] === 'checkoutContentDetailed',
)

const loading = computed(() => cartStore.isCartLoading)
const disabled = computed(
  () =>
    !cartStore.hasAvailableItems ||
    cartStore.hasUnavailableItems ||
    loading.value,
)
</script>
