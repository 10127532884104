export default {
  buttonCheckout: {
    id: 'recap_button',
    defaultMessage: 'Checkout',
  },
  buttonCheckoutABTest: {
    id: 'recap_button_abtest',
    defaultMessage: 'Checkout',
  },
}
