<template>
  <div>
    <RevStickyBar class="md:hidden" :visible="true">
      <div class="flex flex-row items-end">
        <div class="mr-12 flex grow basis-0 flex-col">
          <span class="caption-bold">
            {{ i18n(translations.stickyButtonTotal) }}
          </span>
          <div class="flex flex-wrap">
            <span class="heading-2 mr-4"> {{ i18n.price(totalPrice) }}</span>
            <span
              v-if="marketPlace.features.vatIncluded"
              class="text-static-default-low caption mb-[0.175rem] mr-4 self-end"
            >
              {{ i18n(translations.tvaIncluded) }}</span
            >
            <span
              v-if="withMobilePlan || withSwap"
              class="caption-bold mb-[0.175rem] self-end"
            >
              {{ i18n(translations.stickyPriceDueToday) }}
            </span>
          </div>
          <span
            v-if="withSwap"
            class="text-static-default-low caption truncate"
          >
            {{
              i18n(translations.priceAfterTradeIn, {
                price: i18n.price(
                  // Can't be undefined since we are checking if there is swap present
                  cartStore.totalPriceAfterBuyback!,
                ),
              })
            }}
          </span>
        </div>

        <slot name="button" />
      </div>
    </RevStickyBar>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevStickyBar } from '@ds/components/StickyBar'

import { useCartStore } from '../../../../stores/cartStore'
import { useDiscountStore } from '../../../../stores/discountStore'

import translations from './StickyBar.translations'

withDefaults(
  defineProps<{
    withSwap?: boolean
  }>(),
  {
    withSwap: false,
  },
)

const marketPlace = useMarketplace()
const cartStore = useCartStore()
const discountStore = useDiscountStore()
const i18n = useI18n()

const totalPrice = computed(() =>
  discountStore.isApplied ? discountStore.getDeductedPrice : cartStore.price,
)

const withMobilePlan = computed(() =>
  cartStore.items.some(
    (item) => item.mobilePlan && item.mobilePlan.selectedOffer,
  ),
)
</script>
