export default {
  stickyButtonTotal: {
    id: 'go_to_next_sticky_button_recap_total',
    defaultMessage: 'Cart total',
  },
  stickyPriceDueToday: {
    id: 'cart_sticky_price_title_due_today',
    defaultMessage: 'due today',
  },
  priceAfterTradeIn: {
    id: 'cart_sticky_price_title_after_trade_in',
    defaultMessage: '({price} after trade-in)',
  },
  checkoutButton: {
    id: 'checkout_summary_button_checkout',
    defaultMessage: 'Secure checkout',
  },
  tvaIncluded: {
    id: 'go_to_next_tva_included',
    defaultMessage: 'VAT included',
  },
}
